// src/CharacterTable.js

import React from 'react';
import { hiraganaChart, katakanaChart } from './data';
import './CharacterTable.css'; // Import the CSS for styling

const renderChart = (chart) => (
  <table className="kana-table">
    <tbody>
      {chart.map((row, rowIndex) => (
        <tr key={rowIndex}>
          {row.map((char, colIndex) => (
            <td key={colIndex} className="kana-cell">
              <div className="kana">{char.kana}</div>
              <div className="romaji">{char.romaji}</div>
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

function CharacterTable() {
  return (
    <div className="character-table-container">
      <div className="kana-chart">
        <h2>Hiragana</h2>
        {renderChart(hiraganaChart)}
      </div>
      <div className="kana-chart">
        <h2>Katakana</h2>
        {renderChart(katakanaChart)}
      </div>
    </div>
  );
}

export default CharacterTable;