// src/Quiz.js

import React, { useState, useEffect, useRef } from 'react';
import { hiraganaChart, katakanaChart } from './data';
import './App.css'; // Ensure this includes the new styles

function flattenChart(chart) {
  return chart.flat();
}

function Quiz() {
  const [question, setQuestion] = useState({});
  const [options, setOptions] = useState([]);
  const [feedback, setFeedback] = useState('');
  const feedbackRef = useRef(null); // Ref for the feedback div

  useEffect(() => {
    generateQuestion();
  }, []);

  const generateQuestion = () => {
    const allKana = flattenChart(hiraganaChart).concat(flattenChart(katakanaChart));
    const randomIndex = Math.floor(Math.random() * allKana.length);
    const selectedChar = allKana[randomIndex];

    setQuestion(selectedChar);

    const optionsSet = new Set();
    optionsSet.add(selectedChar.romaji);

    // Ensure unique options
    while (optionsSet.size < 4) {
      const optionChar = allKana[Math.floor(Math.random() * allKana.length)];
      optionsSet.add(optionChar.romaji);
    }

    const optionsArray = Array.from(optionsSet);
    optionsArray.sort(() => Math.random() - 0.5);
    setOptions(optionsArray);
    setFeedback('');
  };

  const handleOptionClick = (option) => {
    if (option === question.romaji) {
      setFeedback('Correct!');
    } else {
      setFeedback('Incorrect. Try again!');
    }

    // Scroll to the feedback div after updating feedback
    setTimeout(() => {
      if (feedbackRef.current) {
        feedbackRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100); // Slight delay to ensure state updates before scrolling
  };

  return (
    <div className="quiz-section">
      <div className="question">{question.kana}</div>
      <div className="options">
        {options.map((option, idx) => (
          <button
            key={idx}
            onClick={() => handleOptionClick(option)}
            className={`option-button ${
              feedback && option === question.romaji
                ? 'correct'
                : feedback && option !== question.romaji
                ? 'incorrect'
                : ''
            }`}
          >
            {option}
          </button>
        ))}
      </div>
      <div
        className={`feedback ${feedback === 'Correct!' ? 'correct-feedback' : 'incorrect-feedback'}`}
        ref={feedbackRef} // Attach the ref here
      >
        {feedback}
      </div>
      {feedback === 'Correct!' && (
        <button onClick={generateQuestion} className="next-button">
          Next Question
        </button>
      )}
    </div>
  );
}

export default Quiz;