// src/App.js

import React from 'react';
import CharacterTable from './CharacterTable';
import Quiz from './Quiz';
import './App.css'; // Ensure App.css exists

function App() {
  return (
    <div className="App">
      <CharacterTable />
      <Quiz />
    </div>
  );
}

export default App;