// src/data.js

export const hiraganaChart = [
  // Vowels
  [
    { kana: 'あ', romaji: 'a' },
    { kana: 'い', romaji: 'i' },
    { kana: 'う', romaji: 'u' },
    { kana: 'え', romaji: 'e' },
    { kana: 'お', romaji: 'o' },
  ],
  // K consonant
  [
    { kana: 'か', romaji: 'ka' },
    { kana: 'き', romaji: 'ki' },
    { kana: 'く', romaji: 'ku' },
    { kana: 'け', romaji: 'ke' },
    { kana: 'こ', romaji: 'ko' },
  ],
  // S consonant
  [
    { kana: 'さ', romaji: 'sa' },
    { kana: 'し', romaji: 'shi' },
    { kana: 'す', romaji: 'su' },
    { kana: 'せ', romaji: 'se' },
    { kana: 'そ', romaji: 'so' },
  ],
  // T consonant
  [
    { kana: 'た', romaji: 'ta' },
    { kana: 'ち', romaji: 'chi' },
    { kana: 'つ', romaji: 'tsu' },
    { kana: 'て', romaji: 'te' },
    { kana: 'と', romaji: 'to' },
  ],
  // N consonant
  [
    { kana: 'な', romaji: 'na' },
    { kana: 'に', romaji: 'ni' },
    { kana: 'ぬ', romaji: 'nu' },
    { kana: 'ね', romaji: 'ne' },
    { kana: 'の', romaji: 'no' },
  ],
  // H consonant
  [
    { kana: 'は', romaji: 'ha' },
    { kana: 'ひ', romaji: 'hi' },
    { kana: 'ふ', romaji: 'fu' },
    { kana: 'へ', romaji: 'he' },
    { kana: 'ほ', romaji: 'ho' },
  ],
  // M consonant
  [
    { kana: 'ま', romaji: 'ma' },
    { kana: 'み', romaji: 'mi' },
    { kana: 'む', romaji: 'mu' },
    { kana: 'め', romaji: 'me' },
    { kana: 'も', romaji: 'mo' },
  ],
  // Y consonant
  [
    { kana: 'や', romaji: 'ya' },
    // ゆ and よ are added separately as they don't fit into a 5-column table
    { kana: 'ゆ', romaji: 'yu' },
    { kana: 'よ', romaji: 'yo' },
  ],
  // R consonant
  [
    { kana: 'ら', romaji: 'ra' },
    { kana: 'り', romaji: 'ri' },
    { kana: 'る', romaji: 'ru' },
    { kana: 'れ', romaji: 're' },
    { kana: 'ろ', romaji: 'ro' },
  ],
  // W consonant
  [
    { kana: 'わ', romaji: 'wa' },
    { kana: 'を', romaji: 'wo' },
  ],
  // N (ん)
  [
    { kana: 'ん', romaji: 'n' },
  ],
  // G consonant (diacritics)
  [
    { kana: 'が', romaji: 'ga' },
    { kana: 'ぎ', romaji: 'gi' },
    { kana: 'ぐ', romaji: 'gu' },
    { kana: 'げ', romaji: 'ge' },
    { kana: 'ご', romaji: 'go' },
  ],
  // Z consonant (diacritics)
  [
    { kana: 'ざ', romaji: 'za' },
    { kana: 'じ', romaji: 'ji' },
    { kana: 'ず', romaji: 'zu' },
    { kana: 'ぜ', romaji: 'ze' },
    { kana: 'ぞ', romaji: 'zo' },
  ],
  // D consonant (diacritics)
  [
    { kana: 'だ', romaji: 'da' },
    { kana: 'ぢ', romaji: 'ji' }, // Often pronounced as 'ji'
    { kana: 'づ', romaji: 'zu' }, // Often pronounced as 'zu'
    { kana: 'で', romaji: 'de' },
    { kana: 'ど', romaji: 'do' },
  ],
  // B consonant (diacritics)
  [
    { kana: 'ば', romaji: 'ba' },
    { kana: 'び', romaji: 'bi' },
    { kana: 'ぶ', romaji: 'bu' },
    { kana: 'べ', romaji: 'be' },
    { kana: 'ぼ', romaji: 'bo' },
  ],
  // P consonant (diacritics)
  [
    { kana: 'ぱ', romaji: 'pa' },
    { kana: 'ぴ', romaji: 'pi' },
    { kana: 'ぷ', romaji: 'pu' },
    { kana: 'ぺ', romaji: 'pe' },
    { kana: 'ぽ', romaji: 'po' },
  ],
  // Small kana (e.g., っ, ゃ, ゅ, ょ) - Optional
  [
    { kana: 'っ', romaji: 'tsu' },
    { kana: 'ゃ', romaji: 'ya' },
    { kana: 'ゅ', romaji: 'yu' },
    { kana: 'ょ', romaji: 'yo' },
    { kana: 'ゎ', romaji: 'wa' }, // Obsolete, often omitted
  ],
];

export const katakanaChart = [
  // Vowels
  [
    { kana: 'ア', romaji: 'a' },
    { kana: 'イ', romaji: 'i' },
    { kana: 'ウ', romaji: 'u' },
    { kana: 'エ', romaji: 'e' },
    { kana: 'オ', romaji: 'o' },
  ],
  // K consonant
  [
    { kana: 'カ', romaji: 'ka' },
    { kana: 'キ', romaji: 'ki' },
    { kana: 'ク', romaji: 'ku' },
    { kana: 'ケ', romaji: 'ke' },
    { kana: 'コ', romaji: 'ko' },
  ],
  // S consonant
  [
    { kana: 'サ', romaji: 'sa' },
    { kana: 'シ', romaji: 'shi' },
    { kana: 'ス', romaji: 'su' },
    { kana: 'セ', romaji: 'se' },
    { kana: 'ソ', romaji: 'so' },
  ],
  // T consonant
  [
    { kana: 'タ', romaji: 'ta' },
    { kana: 'チ', romaji: 'chi' },
    { kana: 'ツ', romaji: 'tsu' },
    { kana: 'テ', romaji: 'te' },
    { kana: 'ト', romaji: 'to' },
  ],
  // N consonant
  [
    { kana: 'ナ', romaji: 'na' },
    { kana: 'ニ', romaji: 'ni' },
    { kana: 'ヌ', romaji: 'nu' },
    { kana: 'ネ', romaji: 'ne' },
    { kana: 'ノ', romaji: 'no' },
  ],
  // H consonant
  [
    { kana: 'ハ', romaji: 'ha' },
    { kana: 'ヒ', romaji: 'hi' },
    { kana: 'フ', romaji: 'fu' },
    { kana: 'ヘ', romaji: 'he' },
    { kana: 'ホ', romaji: 'ho' },
  ],
  // M consonant
  [
    { kana: 'マ', romaji: 'ma' },
    { kana: 'ミ', romaji: 'mi' },
    { kana: 'ム', romaji: 'mu' },
    { kana: 'メ', romaji: 'me' },
    { kana: 'モ', romaji: 'mo' },
  ],
  // Y consonant
  [
    { kana: 'ヤ', romaji: 'ya' },
    // ユ and ヨ are added separately as they don't fit into a 5-column table
    { kana: 'ユ', romaji: 'yu' },
    { kana: 'ヨ', romaji: 'yo' },
  ],
  // R consonant
  [
    { kana: 'ラ', romaji: 'ra' },
    { kana: 'リ', romaji: 'ri' },
    { kana: 'ル', romaji: 'ru' },
    { kana: 'レ', romaji: 're' },
    { kana: 'ロ', romaji: 'ro' },
  ],
  // W consonant
  [
    { kana: 'ワ', romaji: 'wa' },
    { kana: 'ヲ', romaji: 'wo' },
  ],
  // N (ン)
  [
    { kana: 'ン', romaji: 'n' },
  ],
  // G consonant (diacritics)
  [
    { kana: 'ガ', romaji: 'ga' },
    { kana: 'ギ', romaji: 'gi' },
    { kana: 'グ', romaji: 'gu' },
    { kana: 'ゲ', romaji: 'ge' },
    { kana: 'ゴ', romaji: 'go' },
  ],
  // Z consonant (diacritics)
  [
    { kana: 'ザ', romaji: 'za' },
    { kana: 'ジ', romaji: 'ji' },
    { kana: 'ズ', romaji: 'zu' },
    { kana: 'ゼ', romaji: 'ze' },
    { kana: 'ゾ', romaji: 'zo' },
  ],
  // D consonant (diacritics)
  [
    { kana: 'ダ', romaji: 'da' },
    { kana: 'ヂ', romaji: 'ji' }, 
    { kana: 'ヅ', romaji: 'zu' }, 
    { kana: 'デ', romaji: 'de' },
    { kana: 'ド', romaji: 'do' },
  ],
  // B consonant (diacritics)
  [
    { kana: 'バ', romaji: 'ba' },
    { kana: 'ビ', romaji: 'bi' },
    { kana: 'ブ', romaji: 'bu' },
    { kana: 'ベ', romaji: 'be' },
    { kana: 'ボ', romaji: 'bo' },
  ],
  // P consonant (diacritics)
  [
    { kana: 'パ', romaji: 'pa' },
    { kana: 'ピ', romaji: 'pi' },
    { kana: 'プ', romaji: 'pu' },
    { kana: 'ペ', romaji: 'pe' },
    { kana: 'ポ', romaji: 'po' },
  ],
  // Small kana (e.g., ッ, ャ, ュ, ョ) - Optional
  [
    { kana: 'ッ', romaji: 'tsu' },
    { kana: 'ャ', romaji: 'ya' },
    { kana: 'ュ', romaji: 'yu' },
    { kana: 'ョ', romaji: 'yo' },
    { kana: 'ヮ', romaji: 'wa' }, 
  ],
];